* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
.form-card {
  margin-top: 5%;
  width: 60%;
}
.admin-section {
  background-image: url("../../Assets/images/bg.png");
  background-position: center;
  background-repeat: no-repeat;
}
.edit-form-card {
  width: 80%;
}
.edit-section {
  display: flex;
  align-items: center;
}
section {
  padding: 2rem 0;
}
.edit-image-btn {
  height: 50px;
}
.delete-icon {
  height: 45px !important ;
}
.admin-side-bar {
  color: white !important;
}
.admin-nav-bar {
  width: 100%;
  padding: 0px;
}
.admin-brand {
  height: 80px;
  color: white;
  font-weight: 700;
  font-size: 35px;
  padding: 20px;
}
.admin-brand .typed {
  color: white !important;
}
.admin-brand:hover {
  color: white !important;
}

.img-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dotted black;
  border-radius: 20px;
  width: 10rem;
  height: 10rem;
  cursor: pointer;
  font-size: large;
}

label span {
  font-weight: lighter;
  font-size: small;
  padding-top: 0.5rem;
}

input[type="file"] {
  display: none;
}

.edit-image-section {
  display: flex;
  flex-direction: row;
  overflow-x: scroll !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black !important;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: black;
  border-radius: 10px;
  opacity: 0.6;
  transition: 0.1s;
}
/* .edit-image-section::-webkit-scrollbar {
  width: 10px !important;
} */

.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.edit-image,
.image {
  margin: 1rem 0.5rem;
  max-width: 150px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.edit-image button,
.image button {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border: none;
  color: white;
  background-color: rgb(35, 107, 148);
}

.edit-image button:hover {
  background-color: rgba(76, 76, 208, 0.646);
}
.images button:hover {
  background-color: rgba(76, 76, 208, 0.646);
}

.edit-image p,
.image p {
  padding: 0 0.5rem;
  margin: 0;
}

.upload-btn {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  border: none;
  border-radius: 20px;
  width: 10rem;
  height: 3rem;
  color: white;
  background-color: green;
}

label:hover,
.upload-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.error {
  text-align: center;
}

.error span {
  color: red;
}

.login-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login-card {
  width: 30%;
}
.edit-images-section {
  height: 100vh;
}
.ChPass-section {
  height: 100vh;
}
.nav-pills {
  width: 200px !important;
}
.nav-item a {
  width: 100% !important;
}
/* button{
  background-color: inherit !important;
} */

.custom-file-input {
  display: none;
}

.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #f8f9fa;
  cursor: pointer;
}

.upload-button span {
  font-size: 16px;
  font-weight: bold;
}
