@font-face {
  font-family: "Vogue";
  src: url("../../Assets/font/Vogue.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.index-section {
  margin: 0 !important;
  padding: 0;
}

.index-item-section {
  list-style: none;
  display: block;
}

.index-item-section .indexlist-props {
  display: block;
  background: transparent;
  /* margin: 5px 0; */
  overflow: hidden;
  /* height: 6vh; */
  padding: 0px;
  color: white;
}

.index-item-section .indexlist-props .indexitem-button {
  display: block;
  padding: 0;
  font-weight: 600 !important;
  font-size: 35px;
  font-family: "lato";
  transition-property: margin-top;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  margin-top: 0;
  /* line-height: 1.5em; */
  color: inherit;
  text-decoration: none;
  /* z-index: 999; */
}

.indexitem-button {
  width: 100%;
  -webkit-backface-visibility: hidden;
  /* mix-blend-mode: exclusion; */
  /* color: black; */
}

.index-item-section .indexlist-props .indexitem-button:hover {
  margin-top: -1.2em;
  height: auto;
  /* mix-blend-mode: difference; */
}

.index-item-section .indexlist-props .indexitem-button:after {
  content: attr(title);
  color: attr(color);
  display: block;
  /* font-family: "Vogue"; */
  transition-property: margin-top;
  -webkit-text-fill-color: attr(color);
  /* -webkit-text-stroke-width: 1px; */
}

.index-section-main-wrap {
  overflow-y: hidden;
  height: 100vh;
}


.index-item-section {
  /* border: 2px solid transparent; */
  text-transform: uppercase;
  font-family: sans-serif;
  cursor: pointer;
}

/* .index-item-section:hover { */
/* border-color: white; */
/* border-left: transparent;
  border-right: transparent; */
/* } */

.index-item-section:hover .indexlist-props>.index-atend {
  display: flex;
  color: white;
  font-size: 20px;
}

.index-item-section:hover .index-item-button {
  font-family: "Vogue";
  letter-spacing: 0.1rem;
  font-weight: 600;
}

.index-innersection {
  margin-left: 30px;
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 100px;
  height: 95vh;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background: rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: rgba(255, 255, 255, 0.3);
}

.index-list-item>h3 {
  font-weight: 700;
}

.index-list-item>p {
  margin: 10px;
  font-size: 20px;
}

.index-list-item {
  width: 200px;
  transition: all 1.3s ease-in-out;
  cursor: pointer;
}

.index-image {
  z-index: 10;
  position: absolute;
  /* width: auto; */
  height: 25vh;
  object-fit: contain;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.index-image>img {
  height: 100%;
}

.link {
  display: flex;
  padding: 4rem 0 2rem 0;
  z-index: 10;
  justify-content: space-between;
}

.indexImage {
  mix-blend-mode: difference;
  z-index: 10;
}

.hover-reveal {
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  overflow: hidden;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.5s ease-out;
}

.hover-reveal>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  transition: text-transform 0.5s ease-out;
}

.index-item-button {
  overflow: hidden;
  position: relative;
  display: inline-block;
  transition: 0.5s ease-in-out;
}

/* .index-item-button::before,
.index-item-button::after {
  content: attr(data-replace);
  position: absolute;
  width: 100%;
  left: 0;
  color: white;
} */

/* .index-item-button::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translateY(250px);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: white;
} */
.index-atend {
  margin-left: -20%;
  justify-content: center;
  align-items: center;
  display: none;
}

#about-portfolio li a {
  text-decoration: none;
  font-size: 5vw;
  font-weight: 700;
  color: blue !important;
}

.about-wrapper {
  width: 100%;
  position: absolute;
  /* height: 100vh; */
}

.about-section h1 {
  /* font-size: 3vw; */
  font-weight: 100;
  color: white;
  font-family: 'lato';
}

.about-section a {
  font-size: 3vw;
  font-weight: 100;
  color: white;
  text-decoration: none;
  font-family: 'lato';
}

.text-size {
  font-size: 3vw !important;
}

.dont-bump:hover {
  pointer-events: none;
}