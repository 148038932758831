.card-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  /* overflow-y: hidden; */
}

/* .card {
    width: 35vh;
    border: none;
}

.card img {
    max-height: 50vh !important;
} */

.image-container img {
  max-height: 100%;
}

.image2 img {
  max-width: 35vw;
  max-height: 75vh;
}

.last-image {
  position: absolute;
  /* bottom: -300px; */
  /* height: 100%; */
  max-height: 100%;
}

.change-title {
  width: 100%;
  /* height:350px; */
  /* background: #000; */
  font-family: sans-serif;
  font-size: 23px;
  margin: 14px -6px;
  /* overflow:hidden; */
  /* position:relative; */
}

.change-title h1::before {
  color: black;
  content: attr(data-title);
  text-decoration: none;
  position: absolute;
  top: inherit;
  top: -10rem;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.change-title h1::after {
  content: attr(data-title);
  color: red;
  top: inherit;
  top: 0;
}

.project-description {
  width: 300px;
  height: 100vh;
  position: absolute;
  font-size: 2.5rem;
  top: 1%;
  left: 10%;
  display: flex;
  align-items: center;
  text-align-last: end;
  letter-spacing: 2px;
  text-transform: uppercase;
  z-index: 100;
  color: black;
}