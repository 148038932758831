body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: 'lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-scrollbar: none;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  /* overflow: hidden; */
}

::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @font-face {
  font-family: "Heebo";
  src: url("/src/Assets/font/Heebo.eot");
  src: url("/src/Assets/font/Heebo.woff") format("woff"), */
    /* url("CustomFont.otf") format("opentype"),
  url("CustomFont.svg#filename") format("svg"); */
/* } */