.canvas-container {
  width: 100% !important;
  height: 100vh !important;
  /* set the height as per your requirement */
}

#canvas {
  width: 100% !important;
  height: 100% !important;
}

s.cardTemp {
  background: black;
}

.home-image {
  max-height: 400px;
}

.first1 {
  width: 100vw;
  background: red;
  height: 100vh;
}

.second1 {
  width: 100vw;
  background: blue;
  height: 100vh;
}

.testing {
  width: 100vw;
  height: 100vh;
}

.home-slider1 {
  margin: 10px 100px;
  opacity: 1;
  color: blue;
}

.home-slider1-wrapper {
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
  display: flex;
  z-index: none;
  background: transparent;
  width: 100% !important;
  margin-top: 100px;
}

.home-index-title {
  height: 20vh;
  z-index: 10;
  position: absolute;
  margin: 150px 200px;
}

.home-index-title>h1 {
  font-size: 110px;
  font-weight: 700;
}

.slider-image {
  transition: all 4s;
}

#home-main-slider {
  height: 50vh;
  margin-left: 18%;
}

.home-main-slider {
  background: transparent !important;
  width: 100%;
}

.home-page {
  /* height: 100vh; */
  /* width: 100vw; */
  background-color: transparent;
  position: relative;
}

.home-slide-section {
  width: 29%;
  height: 65%;
  background: transparent;
}

.home-slider1 {
  margin: 20px 100px;

  margin-top: 20px;
  /* max-height:400px; */
  /* padding: 3px; */
  transition: margin 0.5 ease-ou;
  -webkit-transition: margin 0.5s ease-out;
  -moz-transition: margin 0.5s ease-out;
  -o-transition: margin 0.5s ease-out;
}

.bottom-slider1-wrapper {
  /* width: 150% !important; */
  background: transparent;
}

.home-slider1 {
  width: 30vw;
  margin: 50px;
}

.bottom-slider {
  position: relative;
  height: 50vh;
  margin-left: 18%;
  /* margin-left: ; */
}

.bottom-slider1 {
  margin: 0px 100px;
  margin-top: -10px;
  max-width: 250px;
  opacity: 1;
  /* padding: 3px; */
  transition: margin 0.5 ease-ou;
  -webkit-transition: margin 0.5s ease-out;
  -moz-transition: margin 0.5s ease-out;
  -o-transition: margin 0.5s ease-out;
}

/* .home-slider1:hover {
  cursor: pointer;
  margin-top: -50px;
}
.bottom-slider1:hover {
  cursor: pointer;
  margin-top: -63px;
} */

.home-title {
  width: 60vw;
  position: fixed;
  z-index: 100;
  left: 130px;
  top: 20%;
  /* -webkit-text-stroke: 1px black; */
  background: transparent;
  mix-blend-mode: difference;
  color: white;
  text-align-last: center;
}

.test-blend {
  background: transparent !important;
  mix-blend-mode: difference !important;
  color: white !important;
  text-align-last: center !important;
  transform: translate3d(0, 0, 0) !important;
}

.home-title>h1 {
  font-size: 9vw !important;
  z-index: 100;
  font-weight: 700;
  text-transform: uppercase;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  /* background: rgba(255, 255, 255, 0.2); */
}

.next-project {
  z-index: 100;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-text-stroke: 1px black;
  background: transparent;
  /* mix-blend-mode: difference; */
  color: white;
  text-align: center;
}

.home-title-hide {
  transition: all 5s ease-out;
  display: none !important;

  visibility: hidden !important;
}

.view-all-projects {
  position: relative;
  height: 300vh;
  width: 100vh;
  background-color: white;
}

.hero-title-container {
  position: relative;
}

.viewall {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  text-align: center;
  /* margin-top: 47vh; */
}

.viewAll-projects {
  height: 100%;
}

.viewall>h1 {
  font-size: 15px;
  /* position: fixed; */
  color: black;
  text-align: center;
  font-size: 10vw;
  z-index: 10;
}

.viewall>h1 {
  /* position: /; */
  padding: 0;
  -webkit-text-stroke: 1px black;
  background: transparent;
  color: transparent;
  text-align: center;
  text-transform: uppercase;
}

.viewall>h1::before {
  content: attr(data-text);
  position: absolute;
  margin-top: 0vh;
  left: 0;
  width: 100%;
  height: 0;
  color: black;
  overflow: hidden;
  transition: 1s;
}

.viewall>h1:hover::before {
  height: 100%;
}

.imgOver {
  z-index: 200;
}

.img-fluid {
  max-height: 100vh;
}

.card {
  border: none;
}

.zoom2 {
  height: 100% !important;
  width: 100% !important;
  display: grid;
  place-items: center;
  position: fixed !important;
  top: 0;
  left: 0;
  background: transparent;
}

.zoom {
  display: grid;
  place-items: center;
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
}

.col-md-6 img {
  max-width: 100% !important;
}

.col-md-4 img {
  max-width: 100% !important;
}

.col-md-7 img {
  max-width: 100% !important;
}

.col-md-5 img {
  max-width: 100% !important;
}

.text-fill {
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-width: 3px;
}

.mobile-wrapper {
  visibility: hidden;
}

.is-last-image {
  justify-content: end;
}

@media only screen and (max-width: 1500px) {
  .indexlist-props {
    height: 60px;
  }
}

@media only screen and (min-width: 2000px) {
  .indexlist-props {
    height: 80px !important;
  }
}

@media only screen and (min-width: 2700px) and (max-width: 3000px) {
  .indexlist-props {
    height: 100px !important;
  }
}

@media only screen and (min-width: 3300px) and (max-width: 4100px) {
  .indexlist-props {
    height: 130px !important;
  }
}

@media only screen and (min-width: 4100px) and (max-width: 5500px) {
  .indexlist-props {
    height: 160px !important;
  }
}

@media only screen and (min-width: 5500px) and (max-width: 6500px) {
  .indexlist-props {
    height: 200px !important;
  }
}

@media only screen and (min-width: 6500px) and (max-width: 7500px) {
  .indexlist-props {
    height: 240px !important;
  }
}

@media only screen and (min-width: 7500px) and (max-width: 9500px) {
  .indexlist-props {
    height: 280px !important;
  }
}

@media only screen and (min-width: 9500px) and (max-width: 12500px) {
  .indexlist-props {
    height: 350px !important;
  }
}

@media only screen and (min-width: 3000px) and (max-width: 3300px) {
  .indexlist-props {
    height: 110px !important;
  }
}

@media only screen and (min-width: 1500px) {
  .indexlist-props {
    height: 60px;
  }
}

@media only screen and (max-width: 1400px) {
  .indexlist-props {
    height: 40px;
  }
}

@media only screen and (min-width: 740px) and (max-width: 925px) {
  .indexlist-props {
    height: 30px;
  }
}

@media only screen and (max-width: 740px) {
  .canvas-container {
    width: 100% !important;
    height: 101vh !important;
    /* set the height as per your requirement */
  }

  .indexlist-props {
    height: 40px;
  }

  .is-last-image {
    width: 100% !important;
    height: 100% !important;
    justify-content: end;
  }

  .home-slide-section {
    width: 33% !important;
    height: 25% !important;
    align-items: center;
    /* overflow-x: hidden; */
    /* flex: 1 auto; */
  }

  img {
    object-fit: contain;
  }

  .custom-position {
    top: -122px !important;
    left: 100px;
    margin-left: 0px !important;
  }

  .tech-slideshow {
    width: 100vw !important;
    overflow-x: hidden;
  }

  .home-page {
    height: 100vh;
  }

  .zoom {
    /* width: 100vw; */
    height: 100vh !important;
    margin: auto auto !important;
  }

  .mobile-wrapper {
    visibility: visible !important;
    z-index: 10 !important;
  }

  .img-fluid {
    height: 100%;
  }

  .image-parent {
    height: 100% !important;
  }

  .bottom-img {
    position: relative !important;
    margin-bottom: 12px;
  }

  .image-parent .image-container {
    flex-direction: column;
    height: 100% !important;
  }

  #homeIndex {
    max-width: 100%;
    width: 100vw;
    height: 100% !important;
  }

  .home-slide-section {
    height: 30%;
    width: 29%;
    margin: 0px !important;
  }

  .ME-button {
    margin-top: 20px !important;
    margin-right: 15px;
  }

  .index-button {
    margin: 20px 0 0 0 !important;
    right: 10px !important;
  }

  .home-title {
    left: 15px;
  }

  .image-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    min-height: 0 !important;
    width: 100%;
    justify-content: center;
    height: 50vh !important;
  }

  .last-image {
    width: 60%;
    top: 50%;
    right: 0px;
  }

  .brenna-first img {
    width: 70%;
  }

  .brenna-start {
    justify-content: start !important;
  }

  .brenna-start img {
    width: 85%;
  }

  .brenna-master {
    /* flex-direction: column; */

  }

  .amoeba img {
    max-width: 100%;
    max-height: 60%;
  }

  .about-button {
    right: 10px !important;
  }

  .ME-button {
    left: -5px !important;
  }

  .null-button {
    left: -5px !important;
  }

  .home-title {
    max-width: 100vw !important;
    width: 100vw;
  }

  .home-title>h1 {
    font-size: 15vw !important;
  }

  .index-image {
    /* display: none !important; */
  }

  .text-size {
    font-size: 2rem !important;
  }

  .index-item-section .indexlist-props {
    height: 2em;
  }

  /* .card image {
    width: 100%;
  }
  .home-page{
    width: 100%;
  }
  .home-title {
    position: absolute !important;
    width: 100%;
  }
  .home-slide-section{
    height: auto;
  }
  .mobile-view {
    max-width: 100%;
    height: 150vh !important;
  }   */
}

@media only screen and (max-width: 480px) {
  .sample-canvas {
    /* width: 100% !important;
    height: 110vh !important; set the height as per your requirement */
  }
}

/* @media only screen and (max-width: 840px) {
  .canvas-container {
    width: 100% !important;
    height: 400vh !important; 
  }
} */

@media (min-height: 1000px) and (max-height: 1300px) {

  /* Your styles here */
  .sample-canvas {
    /* width: 100% !important; */
    /* height: 340vh !important; */
  }
}

.margin-left {
  margin-left: 10%;
}

.bannerCanvas {
  position: relative;
}

.bannerCanvas .canvas-container {
  width: 100% !important;
  height: 100vh !important;
  background: #ededed;
  overflow: hidden !important;
}

.bannerCanvas .canvas-container>canvas.lower-canvas {
  width: 100% !important;
  height: auto !important;
  top: 50% !important;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .bannerCanvas .canvas-container>canvas.lower-canvas {
    width: auto !important;
    height: 500px !important;
    left: 50% !important;
    transform: translateY(-50%) translateX(-50%);
  }
}

.my--home--text {
  width: 900px !important;
}

@media only screen and (max-width: 600px) {
  .my--home--text {
    width: 384px !important;
  }
}