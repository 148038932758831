* {
  font-family: 'lato', sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  /* overflow-y: scroll !important; */
  transition: 1s all ease-in-out;
  background: white;
  scroll-snap-type: smooth;
}

.index-button {
  mix-blend-mode: difference;
  background: transparent;
  color: black;
  margin-top: 50px;
  font-weight: 700;
  writing-mode: vertical-rl;
  z-index: 99999;
  cursor: pointer;
  right: 30px;
  position: fixed;
}

.about-button {
  mix-blend-mode: difference;
  background: transparent;
  color: black;
  margin: 30px 0px;
  font-weight: 700;
  writing-mode: vertical-rl;
  z-index: 99999;
  cursor: pointer;
  right: 30px;
  bottom: 0;
  position: fixed;
}

.about-button a {
  color: white !important;
}

.index-button a {
  color: white !important;
}

.null-button a {
  color: white !important;
}

h3 {
  font-size: 1.45rem !important;
}

.ME-button {
  mix-blend-mode: difference;
  background: transparent;
  color: black;
  font-weight: 700;
  margin-top: 50px;
  margin-left: 15px;
  writing-mode: vertical-rl;
  z-index: 99999;
  cursor: pointer;
  left: 0;
  position: fixed;
}

.ME-button a {
  color: white;
}

.null-button {
  mix-blend-mode: difference;
  background: transparent;
  color: black;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 15px;
  font-weight: 700;
  writing-mode: vertical-rl;
  z-index: 99999;
  cursor: pointer;
  left: 0;
  bottom: 0;
  position: fixed;
}

.card1 {
  width: 100px;
  height: 100px;
  background-color: rgb(36, 124, 73);
  position: absolute;
}

.card2 {
  width: 100px;
  height: 100px;
  position: absolute;
  background-color: rgb(43, 194, 164);
}

img.hoverImages {
  margin: 30px;
  padding: 3px;
  -webkit-transition: margin 0.5s ease-out;
  -moz-transition: margin 0.5s ease-out;
  -o-transition: margin 0.5s ease-out;
}

img.hoverImages:hover {
  cursor: pointer;
  margin-top: 20px;
  margin-left: 2px;
}

#style-2 {
  overflow: hidden;
  position: relative;
  display: inline-block;
  z-index: 9999;
}

#style-2::before,
#style-2::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  mix-blend-mode: difference;
  background: transparent;
  color: black;
}

/*
#style-2::before {
  background-color: black;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  mix-blend-mode: difference;
  background: transparent;
  color: black;
}

#style-2::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
  mix-blend-mode: difference;
  background: transparent;
  color: black;
}
*/

#style-2:hover::after {
  transform: translate3d(0, 0, 0);
  mix-blend-mode: difference;
  background: transparent;
  color: black !important;
}

#style-2:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
  mix-blend-mode: difference;
  background: transparent;
  color: black !important;
}

#style-2 span {
  display: inline-block;
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

#style-2:hover {
  /* transform: translate3d(-200%, 0, 0); */
  /* mix-blend-mode: difference; */
  /* background: transparent; */
  color: white !important;
}

.video-wrapper video {
  z-index: 10;
}

/* home page start*/

@media (max-width: 767px) {

  .amoeba img,
  .auston img,
  .brenna img,
  .mobile-view img {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.load {
  width: 100vw;
  height: 100vh;
  background-color: white;
  /* background: url("https://images.unsplash.com/photo-1523821741446-edb2b68bb7a0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80") no-repeat center center fixed;
  background-size: cover; */
  position: fixed !important;
  z-index: 100000;
}

.load .loader {
  border: 3px solid #f3f3f3;
  border-top: 5px solid #252525;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  margin: auto auto;
  margin-top: 40vh;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.my-gradients-colors {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}