.amoeba-section {
  position: relative;
  margin-top: 10%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.amoeba-imge1 {
  margin-top: 10%;
  max-width: 400px;
  max-height: 400px;
}
.amoeba-imge2 {
  margin-left: 10%;
  max-width: 500px;
  max-height: 500px;
}
.amoeba-imge3 {
  max-width: 500px;
  max-height: 500px;
  margin-left: 5%;
}
